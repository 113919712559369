import React from "react";
import Logo from "../Logo/Logo";
import PadName from "../PadName/PadName";
import Navigations from "../Navigations/Navigations";
import MyHeader from "./Header.module.css";
import { ShareModal } from "../ShareModal/ShareModal";
import { UserAvatar } from "../Avatar/UserAvatar";
import privateIcon from '../../assets/lock.svg';
import publicIcon from '../../assets/world.svg';
import { useSelector } from "react-redux";
import DocumentMembers from "../Members/members";
import Modes from './modes.js'
import PrintPad from "../Print/print.js";
import CompleteDocument from "../CompleteDoc/completeDoc.js";
import SavingStatus from "../SavingStatus/savingStatus .js";

const Header = ({ isMobile }) => {
  const documentInformation = useSelector((state) => state.editor.documentInformation);
  const userInformation = useSelector((state) => state.editor.userInformation);
  const isReadOnly = useSelector((state) => state.editor.isReadOnly);
  
  return (
    <div className="my-header" style={{ backgroundColor: 'white', height: "91px", width: "100%", position: "fixed", top: '0', zIndex: 9999 }}>
      <div className={MyHeader.headerMain} style={{ marginBottom: 10, top: '12px' }}>
        <Logo isMobile={isMobile} />
        <div className={MyHeader.headerCol}>
          <div style={{ display: "flex", gap: '5px', marginRight: !isMobile ? 590 : '' }}>
            <PadName isMobile={isMobile} />
            {documentInformation.privacyId == 2 ? (
              <><img src={privateIcon} alt='Private documnet' style={{ width: '15px', marginLeft: isMobile ? '120px' : '' }} />
              </>
            )
              : (
                <><img src={publicIcon} alt='Public documnet' style={{ width: '18px', marginLeft: isMobile ? '120px' : '' }} /></>)}
            <PrintPad />
            {
              !isReadOnly && <CompleteDocument/>
            }
            <SavingStatus />
          </div>
          <div style={{ marginTop: '18px' }}>
            <Navigations />
          </div>
        </div>

        <div
          className={MyHeader.titleBarButtons}
          style={{
            width: documentInformation.createBy !== userInformation.userId ? '116px' : '180px',
            display: 'flex',
            width: 'auto',
            justifyContent: 'space-between',
            gap: '8px'
            // margin: "0 8px",
          }}
        >
          <DocumentMembers />
          {/* <VersionsModal /> */}
          {documentInformation.createBy == userInformation.userId && (
            <>
              <ShareModal />

            </>
          )}
          <Modes isMobile={isMobile} />
          <UserAvatar isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
};

export default Header;
