import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import savingIcon from '../../assets/SavingIcon.png';
import savedIcon from '../../assets/savedIcon.png';
import { useSelector } from 'react-redux';
import  "./savingStatus.css";

const SavingStatus = () => {
    const savingData = useSelector((state) => state.editor.savingData);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1096);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1096);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'SavedData-Conatiner'}>
            {savingData && (
                <>
                    {savingData.isSaving === 'Saving...' ? (
                        // Show "Saving..." icon and text
                        <>
                            <img
                                src={savingIcon}
                                alt="Saving document"
                                style={{ width: '22px' }}
                            />
                            <span>{savingData.isSaving}</span>
                        </>
                    ) : savingData.isSaving === 'Saved' ? (
                        isSmallScreen ? (
                            // Show tooltip on smaller screens
                            <Tooltip
                                title={
                                    savingData.lastSavedDateTime
                                        ? `${savingData.lastSavedDateTime}, ${savingData.fileSize}`
                                        : savingData.fileSize // If no lastSavedDateTime, only show file size
                                }
                            >
                                <img
                                    src={savedIcon}
                                    alt="Saved document"
                                    style={{ width: '24px', cursor: 'pointer' }}
                                />
                            </Tooltip>
                        ) : (
                            // Show details directly in the header on larger screens
                            <>
                                <img
                                    src={savedIcon}
                                    alt="Saved document"
                                    style={{ width: '24px', cursor: 'default' }}
                                />
                                <span>
                                    {savingData.lastSavedDateTime}
                                    {`, ${savingData.fileSize}`}
                                </span>
                            </>
                        )
                    ) : (
                        // If neither "Saving..." nor "Saved" and fileSize exists, show only the file size
                        savingData.fileSize && <span>{savingData.fileSize}</span>
                    )}
                </>
            )}
        </div>
    );
};

export default SavingStatus;
